import React from "react";
import * as styles from "./styles.module.scss";

const SocialLinks = () => {
    return (
        <ul className={styles.socialLinks}>
            <li>
                <a href="https://www.facebook.com/leesreview/">
                    <Facebook />
                </a>
            </li>
            <li>
                <a href="https://www.yelp.com/biz/lees-review-diamond-bar">
                    <Yelp />
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/leesreviewlearning">
                    <Instagram />
                </a>
            </li>
        </ul>
    );
};

export default SocialLinks;

const Facebook = () => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                clipRule="evenodd"
                d="M0 4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0H16C17.0609 0 18.0783 0.421427 18.8284 1.17157C19.5786 1.92172 20 2.93913 20 4V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20H4C2.93913 20 1.92172 19.5786 1.17157 18.8284C0.421427 18.0783 0 17.0609 0 16V4ZM4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H10V11H9C8.73478 11 8.48043 10.8946 8.29289 10.7071C8.10536 10.5196 8 10.2652 8 10C8 9.73478 8.10536 9.48043 8.29289 9.29289C8.48043 9.10536 8.73478 9 9 9H10V7.5C10 6.57174 10.3687 5.6815 11.0251 5.02513C11.6815 4.36875 12.5717 4 13.5 4H14.1C14.3652 4 14.6196 4.10536 14.8071 4.29289C14.9946 4.48043 15.1 4.73478 15.1 5C15.1 5.26522 14.9946 5.51957 14.8071 5.70711C14.6196 5.89464 14.3652 6 14.1 6H13.5C13.303 6 13.108 6.0388 12.926 6.11418C12.744 6.18956 12.5786 6.30005 12.4393 6.43934C12.3001 6.57863 12.1896 6.74399 12.1142 6.92597C12.0388 7.10796 12 7.30302 12 7.5V9H14.1C14.3652 9 14.6196 9.10536 14.8071 9.29289C14.9946 9.48043 15.1 9.73478 15.1 10C15.1 10.2652 14.9946 10.5196 14.8071 10.7071C14.6196 10.8946 14.3652 11 14.1 11H12V18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2H4Z"
                fillRule="evenodd"
            />
        </svg>
    );
};

const Yelp = () => {
    return (
        <svg
            fill="none"
            height="21"
            viewBox="0 0 16 21"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.59007 0C7.23007 0 7.50007 0.27 7.58007 0.97L7.79007 4.14L8.03007 8.29C8.05007 8.64 8.00007 9 7.86007 9.32C7.64007 9.77 7.14007 9.89 6.73007 9.58C6.50007 9.39 6.31007 9.14 6.15007 8.87L2.42007 2.55C2.06007 1.94 2.17007 1.54 2.77007 1.16C3.50007 0.68 5.73007 0 6.59007 0ZM10.8301 12.85L11.0901 12.91L14.9501 14.31C15.6101 14.55 15.7901 14.92 15.5001 15.57C15.0601 16.7 14.3401 17.66 13.4201 18.45C12.9601 18.85 12.5001 18.78 12.2101 18.28L9.94007 14.32C9.55007 13.61 10.0301 12.8 10.8301 12.85ZM0.500069 12C0.500069 11.26 0.500069 10.55 0.750069 9.87C0.970069 9.2 1.33007 9 2.00007 9.27L5.63007 10.81C6.09007 11 6.35007 11.32 6.33007 11.84C6.30007 12.36 5.97007 12.58 5.53007 12.73L1.85007 13.94C1.15007 14.17 0.790069 13.96 0.640069 13.25C0.550069 12.83 0.470069 12.4 0.500069 12ZM7.97007 19C7.95007 19.81 7.60007 20.12 6.81007 20C5.77007 19.8 4.81007 19.4 3.96007 18.76C3.54007 18.44 3.45007 17.95 3.76007 17.53L6.47007 13.97C6.70007 13.67 7.03007 13.6 7.39007 13.74C7.77007 13.88 7.97007 14.18 7.97007 14.59V19ZM10.4501 11.32C9.73007 11.33 9.23007 10.5 9.64007 9.91C10.4701 8.67 11.3501 7.46 12.2301 6.26C12.5001 5.85 12.9401 5.82 13.3101 6.16C14.2401 7 14.9101 8 15.2901 9.22C15.4301 9.67 15.2501 10.08 14.8301 10.2L11.0901 11.17L10.4501 11.32Z" />
        </svg>
    );
};

const Instagram = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 24 24">
            <path d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"></path>
        </svg>
    );
};
