// extracted by mini-css-extract-plugin
export var container = "styles-module--container--pJ-Mi";
export var clicked = "styles-module--clicked--KGsZZ";
export var closeButton = "styles-module--closeButton---UaE+";
export var imageContainer = "styles-module--imageContainer--ZF+mf";
export var college = "styles-module--college--ESWQ0";
export var image = "styles-module--image--BbLWi";
export var content = "styles-module--content--lQIwU";
export var tagContainer = "styles-module--tagContainer--BedMR";
export var tag = "styles-module--tag--3-mjA";
export var title = "styles-module--title--dOmvs";
export var quote = "styles-module--quote--QHb3u";